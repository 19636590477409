import axios from 'axios'
// import { createPinia } from 'pinia'
// import { useStore } from '@/store/ustore'
import { getToken } from '@/utils/auth'
import qs from 'qs'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router'
// const museStore = useStore(createPinia())
const service = axios.create({
  baseURL: 'https://bk.hnbods.com/api.php/consulting',
  timeout: 60000,
})
service.interceptors.request.use(
  config => {
    config.headers['Authorization'] = 'Bearer ' + getToken()
    config.headers['XX-Device-Type'] = 'pc'
    if (config.method === 'post') {
      config.data = qs.stringify(config.data)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  response => {
    const resmsg = response.data.msg
    const res = response.data.data
    res.code = res.code ? res.code : 200
    if (res.code !== 200 && res.code !== 10001) {
      return ElMessage({
        message: resmsg,
        type: 'error',
        duration: 2 * 1000
      })
    } else if (res.code === 10001) {
      ElMessageBox.alert(resmsg, '提示', {
        confirmButtonText: '确定',
        callback: () => {
          router.push('/login')
        }
      })
    } else {
      return res
    }
  },
  error => {
    ElMessage({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
