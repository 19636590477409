import request from '@/utils/request'

export function getHomeImg() {
  return request({
    url: '/home/getHomeImg',
    method: 'post',
    data: {}
  })
}
export function getDigital() {
  return request({
    url: '/home/digital',
    method: 'post',
    data: {}
  })
}
export function getSearchDigital(name) {
  return request({
    url: '/home/serarchDigital',
    method: 'post',
    data: {
      searchName:name
    }
  })
}
export function getPageDitigal(data) {
  return request({
    url: '/digital/getPageDitigal',
    method: 'post',
    data
  })
}
