<template>
    <div class="digiTal">
        <IndexTop></IndexTop>
        <div class="search-box">
            <input v-model="serarchName" type="text" placeholder="请输入搜索关键字">
            <button @click="toSearch">搜索</button>
        </div>
        <div class="hot-word">
            <span v-for="(item, index) in hotWord" :key="index" @click="toSearchHotWord(item)">{{ item }}</span>
        </div>
        <div class="content">
            <div class="con-list" v-for="(item, index) in digiTalData" :key="index">
                <div class="content-list">
                    <div class="list-left">
                        <img :src="item.img_url" alt="">
                    </div>
                    <div class="list-center">
                        <div class="center-top">
                            <span>{{ item.title }}</span>
                            <span>{{ item.c_time }}</span>
                        </div>
                        <p>{{ item.des }}</p>
                    </div>
                    <div class="list-right"></div>
                </div>
                <el-collapse v-model="activeName" accordion>
                    <el-collapse-item title="查看详情" :name="index">
                        <div class="detail">
                            <embed :src="item.file_url" type="application/pdf" width="100%" height="500px"><embed>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>

        </div>
        <div class="no-data">
            <el-empty :image-size="300" v-if="digiTalData.length === 0" description="暂无相关内容~" />
            <div class="content-page" v-else>
                <el-pagination v-model:current-page="currentPage1" :page-size="pageSize" :small="small"
                    :disabled="disabled" :background="background" layout="total, prev, pager, next" :total="total"
                    @current-change="handleCurrentChange" />
            </div>
        </div>

        <IndexFoot></IndexFoot>
    </div>
</template>
<script setup>
import IndexTop from '../components/indexTop.vue'
import IndexFoot from '../components/indexFoot.vue'
import { ref, onMounted } from 'vue'
import { getDigital, getSearchDigital,getPageDitigal} from '@/api/home'
const activeName = ref('0')
const digiTalData = ref([])
const loading = ref(false)
const hotWord = [
    '数据治理',
    '阿里巴巴',
    '数据分析',
    '数据分析',
    '数据采集'
]
const currentPage1 = ref(1)
const pageSize = ref(9)
const total = ref(0)
const serarchName = ref('')
onMounted(() => {
    getDigital().then(res => {
        digiTalData.value = res.data
        total.value = res.total
    })
})
const toSearchHotWord = (word) => {
    serarchName.value = word
    getSearchDigital(serarchName.value).then(res => {
        digiTalData.value = res.data
    })
}
const toSearch = () => {
    getSearchDigital(serarchName.value).then(res => {
        digiTalData.value = res.data
    })
}

function handleCurrentChange() {
    loading.value = true
    let params1 = {
        current_page: currentPage1.value,
        page_size: pageSize.value
    }
    getPageDitigal(params1).then(res => {
        digiTalData.value = res.data
        loading.value = false
    })
}

</script>
<style scoped lang="scss">
.digiTal {
    .search-box {
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 500px;
        height: 40px;
        margin: 110px auto 10px;

        input {
            height: 35px;
            width: 400px;
        }

        button {
            width: 80px;
            height: 35px;
            color: #fff;
            background: #003E9F;
            cursor: pointer;
        }

        button:hover {
            border: 1px solid #ccc;
        }
    }

    .hot-word {
        margin: 0 auto;
        display: flex;
        width: 500px;
        height: 20px;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 20px;

        span {
            font-size: 14px;
            margin-right: 10px;
            color: #7a7a7a;
        }

        span:hover {
            color: #003E9F;
            cursor: pointer;
        }
    }

    .content-page {
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .no-data {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .content {
        // min-height: calc(100vh - 80px);
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        font-size: 20px;
        width: 90%;
        margin: 50px auto;
        flex-wrap: wrap;

        .con-list {
            width: 30%;
        }

        .detail {
            display: flex;
            flex-direction: column;

            img {
                width: 100%;
                height: auto;
            }

        }

        .content-list {
            padding-right: 20px;
            box-sizing: border-box;
            margin-top: 20px;
            border-radius: 5px;
            background: white;
            display: flex;
            height: 200px;

            .list-left {
                width: 200px;
                flex-shrink: 0;
                margin-right: 20px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .list-center {
                .center-top {
                    margin-top: 10px;
                    display: flex;
                    flex-direction: column;
                    :nth-child(1) {
                        font-size: 20px;
                        font-weight: bold;
                        margin-right: 20px;
                        margin-bottom: 10px;
                    }

                    :nth-child(2) {
                        font-size: 14px;
                        color:#7a7a7a;
                    }

                }

                p {
                    height: 120px;
                    font-size: 15px;
                    text-indent: 30px;
                    overflow-y: auto;
                }
            }
        }
    }
}
</style>
